@import '../../mixins';

.advantages-card {
  width: rem(526);
  min-height: rem(237);
  height: auto;
  display: grid;
  grid-template-columns: 1fr rem(180);

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common);

  overflow: hidden;
  
  @include mediaDesktop {
    grid-template-columns: 1fr rem(280);
  }
  
  @include mediaBigDesktop {
    width: big(526);
    min-height: big(237);
    grid-template-columns: 1fr big(280);
  }

  @include mediaLaptop {
    width: rem(400);
    min-height: rem(196);

    grid-template-columns: 1fr rem(147);
  }

  @include mediaTablet {
    width: 100%;
    min-height: rem(133);

    grid-template-columns: 1fr rem(133);
  }

  @include media(#{rem(550)}) {
    display: flex;
    flex-direction: column;
  }

  &__content {
    padding: rem(30);
    grid-column: 1/2;

    display: flex;
    flex-direction: column;
    gap: rem(10);

    @include mediaBigDesktop {
      padding: big(30);

      gap: big(10);
    }

    @include mediaLaptop {
      padding: rem(20);

      gap: rem(5);
    }

    @include media(#{rem(550)}) {
      padding: rem(15);
    }
  }

  &__title {
    color: var(--text-dark-primary);
  }

  &__text {
    color: var(--text-dark-secondary);
  }

  &__image {
    width: 100%;

    pointer-events: none;
    user-select: none;

    @include media(#{rem(550)}) {
      width: 100%;
      height: rem(170);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      mask-image: url("data:image/svg+xml,%3Csvg width='180' height='237' viewBox='0 0 180 237' fill='none' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M58 236.992C22.721 209.744 0 167.025 0 119C0 70.3786 23.2887 27.1964 59.3199 9.87448e-08C87.8826 -9.27964e-06 141.281 0.000652844 176.009 0.000508974C178.219 0.000499822 180 1.74686 180 3.956C180 65.5574 180 188.127 180 233.012C180 235.221 178.219 237 176.01 237C152.58 237 91.1658 236.992 58 236.992Z' fill='black'/%3E%3C/svg%3E%0A");
      mask-size: auto 100%;
      mask-repeat: no-repeat;
      mask-position: right;
      
      @include mediaBigDesktop {
        mask-image: url("data:image/svg+xml,%3Csvg width='280' height='280' viewBox='0 0 180 237' fill='none' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M58 236.992C22.721 209.744 0 167.025 0 119C0 70.3786 23.2887 27.1964 59.3199 9.87448e-08C87.8826 -9.27964e-06 141.281 0.000652844 176.009 0.000508974C178.219 0.000499822 180 1.74686 180 3.956C180 65.5574 180 188.127 180 233.012C180 235.221 178.219 237 176.01 237C152.58 237 91.1658 236.992 58 236.992Z' fill='black'/%3E%3C/svg%3E%0A");  
      }
      
      @include mediaLaptop {
        mask-position: unset;
      }

      @include mediaTablet {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='119' height='133' viewBox='0 0 119 133' fill='none' preserveAspectRatio='none'%3E%3Cpath d='M28.9034 132.995C11.5252 117.704 0.333008 93.7313 0.333008 66.7805C0.333008 39.4951 11.8049 15.2621 29.5535 5.54136e-08C43.1846 -5.04343e-06 94.3746 0.000343555 115.014 0.000291885C117.223 0.000286354 119 1.77606 119 3.98521C119 38.2937 119 103.009 119 129.032C119 131.241 117.211 133 115.002 133C98.0314 132.999 44.6601 132.995 28.9034 132.995Z' fill='black' /%3E%3C/svg%3E");
      }

      @include media(#{rem(550)}) {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='290' height='170' viewBox='0 0 290 170' fill='none' preserveAspectRatio='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M290 47.9839V170H0V47.9839C40.4752 17.8403 90.6542 0 145 0C199.346 0 249.525 17.8403 290 47.9839Z' fill='black' /%3E%3C/svg%3E");
        mask-size: 100% 100%;
        mask-position: center end;
      }
    }
  }
}
